<template>
  <div class="container-fluid">
    <PickupPointNavTab />
    <div class="card p-2">
      <div class="row p-1 align-items-center">
        <div class="col-md-6">
          <h2 class="roboto subtitle bold">List Addresses - Pick-up Point</h2>
        </div>
      </div>
      <div>
        <PickupPointCard
          v-for="pickupPoint in listPickupPoint.items"
          :point="pickupPoint"
          :key="pickupPoint.id"
          @deletePickupPoint="selectedPickupPoint = $event"
        />
      </div>
    </div>
    <b-modal
      id="modal-delete-pickup"
      hide-header
      centered
      @ok="deletePickupPoint(selectedPickupPoint.id)"
    >
      <p class="text-center m-0">
        <b v-if="selectedPickupPoint">{{selectedPickupPoint.name}}</b><br/>
        Are you sure you want to delete this pickup point?
      </p>
    </b-modal>
  </div>
</template>
<script>
import { toaster } from "@/_helpers";
import PickupPointNavTab from "@/components/pickupPoint/PickupPointNavTab";
import PickupPointCard from "@/components/pickupPoint/PickupPointCard";

export default {
  components: { PickupPointNavTab, PickupPointCard },
  data() {
    return {
      selectedPickupPoint: null,
      listPickupPoint: {
        total: 0,
        items: [],
        limit: 5,
        page: 1,
      },
    };
  },
  mounted() {
    this.refreshList();
  },
  methods: {
    refreshList() {
      this.getPickupPointList();
    },
    async getPickupPointList() {
      try {
        const res = await this.$api.pickuppoint.getPickupPointList();
        if (res.status === 200) {
          this.listPickupPoint.items = res.data.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async deletePickupPoint(pickupPointId) {
      try {
        const res = await this.$api.pickuppoint.deletePickupPoint(pickupPointId)
        if (res.status === 200) {
          toaster.make(res.data.message, 'success')
          this.listPickupPoint.items.splice(
            this.listPickupPoint.items.findIndex(el => el.id == pickupPointId), 1
          )
        }
      } catch (e) {
        console.error(e)
      }
    }
  },
};
</script>
