<template>
  <div v-if="point.merchant_id != 0 && point.merchant_id != null" class="m-2">
    <b-card>
      <b-card-text>
        <strong>
          {{ point.name }}
        </strong>
        (Duration: {{ point.duration }} Days) - Operation:
        {{
          point.operating_day
            ? point.operating_day
            : "Senin, Selasa, Rabu, Kamis, Jumat"
        }}
        <span v-if="point.operating_start_time && point.operating_end_time">
          ({{ momentTime(point.operating_start_time) }} WIB -
          {{ momentTime(point.operating_end_time) }} WIB)
        </span>
        <span v-else> (10:00 WIB - 17:00 WIB) </span>
      </b-card-text>
      <b-card-text v-if="point.address">
        {{ point.address }}
      </b-card-text>
      <b-card-text>
        <em>Contact: </em>&nbsp;{{ point.contact ? point.contact : "-" }}
      </b-card-text>

      <div class="d-flex justify-content-end">
        <b-link :to="`/edit-pickup/${point.id}`" class="card-link">Edit</b-link>
        <b-link v-b-modal.modal-delete-pickup @click="$emit('deletePickupPoint', point)" class="card-link">Delete</b-link>
      </div>
    </b-card>
  </div>
</template>
<script>
import moment from "moment";

export default {
  //  id, merchant_id, name, address, contact, duration, latitude, longitude, operating_start_time, operating_end_time, operating_day
  name: "PickupPointCard",
  props: ["point"],
  methods: {
    momentTime(time) {
      return moment(String(time), "HH:mm:ss").format("HH:mm").toString();
    },
  },
};
</script>

<style scoped>
.card-text {
  margin-bottom: 5px;
}
.card-body {
  padding: 10px;
}
</style>